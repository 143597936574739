export default class Tabs {
  constructor(element) {
    this.element = element;
    this.tabs = this.element.querySelectorAll('.js-tab');
    this.tabsContent = this.element.querySelectorAll('[data-tab-content-id]');

    this.init();
  }

  init() {
    this.setOptions();

    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i];
      tab.addEventListener('click', this.toggleTab.bind(this));
    }
  }

  setOptions() {
    if ('defaultTab' in this.element.dataset) {
      const tabId = this.element.dataset.defaultTab;
      const tab = this.element.querySelector(`[data-tab-id="${tabId}"]`);
      const tabContent = this.element.querySelector(
        `[data-tab-content-id="${tabId}"]`
      );
      tab.classList.add('is-active');
      tabContent.classList.add('is-active');
    } else {
      this.tabs[0].classList.add('is-active');
      this.tabsContent[0].classList.add('is-active');
    }
  }

  toggleTab(e) {
    const target = e.currentTarget;
    const id = target.dataset.tabId;

    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i];
      if (tab === target) {
        tab.classList.add('is-active');
      } else {
        tab.classList.remove('is-active');
      }
    }

    for (let i = 0; i < this.tabsContent.length; i++) {
      const tabContent = this.tabsContent[i];
      if (tabContent.dataset.tabContentId === id) {
        tabContent.classList.add('is-active');
      } else {
        tabContent.classList.remove('is-active');
      }
    }
  }
}
