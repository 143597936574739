/** Componsante Accordion de TimTools */
export default class Accordion {
  /**
   * Méthode constructeur
   * @param {HTMLElement} element - Élément HTML sur lequel la composante est instanciée
   */
  constructor(element) {
    this.element = element;
    this.headers = this.element.querySelectorAll('.js-header');

    if ('notClosing' in this.element.dataset) {
      this.notClosing = true;
    }

    this.init();
  }

  /**
   * Méthode d'initialisation
   */
  init() {
    let count = 0;

    for (let i = 0; i < this.headers.length; i++) {
      const header = this.headers[i];
      header.addEventListener('click', this.toggleActive.bind(this));
      console.log(header.dataset);
      if ('autoOpen' in header.dataset) {
        header.classList.add('is-active');
        count++;
      }
    }
    if (count > 1) {
      this.notClosing = true;
    }
  }

  toggleActive(e) {
    for (let i = 0; i < this.headers.length; i++) {
      const header = this.headers[i];
      if (header === e.currentTarget) {
        header.classList.toggle('is-active');
      } else {
        if (!this.notClosing) header.classList.remove('is-active');
      }
    }
  }
}
