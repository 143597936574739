import Cache from "../utils/Cache";

export default class Checklist {
  constructor(element) {
    this.element = element;
    this.id = this.element.dataset.id;
    this.checks = this.element.querySelectorAll(".js-check");
    if (this.checks.length) {
      this.restoreStatus();
    } else {
      this.count();
    }
    this.init();
  }

  init() {
    for (let i = 0; i < this.checks.length; i++) {
      const check = this.checks[i];
      check.addEventListener("click", this.onClick.bind(this));
    }
  }

  onClick(e) {
    const name = e.currentTarget.name;
    const isChecked = e.currentTarget.checked;
    Cache.set(name, isChecked, true);
  }

  count() {
    const countEl = this.element.querySelector(".js-count");
    const total = Cache.get(`${this.id}-total`);

    if (countEl && total) {
      let completed = 0;
      for (let i = 0; i < total; i++) {
        if (Cache.get(`${this.id}_${i}`) === "true") {
          completed += 1;
        }
      }
      countEl.innerHTML = `${completed}/${total}`;
    }
  }

  restoreStatus() {
    Cache.set(`${this.id}-total`, this.checks.length, true);

    for (let i = 0; i < this.checks.length; i++) {
      const check = this.checks[i];
      if (Cache.get(check.name) === "true") {
        check.checked = true;
      }
    }
  }
}
